const _encode = (v) => {
	if (v === undefined || v === null) return ''
	return encodeURIComponent(v)
}

export const queryString = (params: any) => {
	const query = Object.keys(params)
		.map((key) => _encode(key) + '=' + _encode(params[key]))
		.join('&')
	return `?${query}`
}
