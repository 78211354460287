import React from 'react';
import { useOneClick } from '../../../../contexts/OneClickBuyContext';
import { ButtonClose } from '../../styles/ButtonClose';
import { ErrorMessage } from './ErrorMessage';

const Error: React.FC = () => {
	const { setIsModalOpen, error, paymentError } = useOneClick();
	return (
		<ErrorMessage>
			<div className='ticto-error-message'>
				<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"></path></svg>
				<h1>{error ?? paymentError}</h1>
				<ButtonClose onClick={() => setIsModalOpen(false)}>
					FECHAR
				</ButtonClose>
			</div>
		</ErrorMessage>
	)
}

export default Error;
