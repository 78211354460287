import { memo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Tooltip } from 'react-tooltip'

interface ClipboardProps {
	text: string
	clipboard: {
		value: string
		copied: boolean
	}
	setClipboard: (clipboard) => void
}

const Clipboard: React.FC<ClipboardProps> = ({ text, clipboard, setClipboard }) => {
	return (
		<>
			<CopyToClipboard
				text={text}
				onCopy={() => {
					setClipboard({ value: text, copied: true })
				}}
			>
				<span data-tooltip-id={text} data-tooltip-content={clipboard.copied ? 'Copiado' : 'Clique para copiar'}>
					{text}
					<Tooltip id={text} />
				</span>
			</CopyToClipboard>
		</>
	)
}
export default memo(Clipboard)
