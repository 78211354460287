import React from 'react';
import { Spinning } from '../../../../components/Spinning';
import { ButtonClose } from '../../../../components/Upsell/styles/ButtonClose';
import { useOneClick } from '../../../../contexts/OneClickBuyContext';
import { SuccessMessage } from './SuccessMessage';

const Success: React.FC = () => {
	const { setIsModalOpen } = useOneClick();
	return (
		<SuccessMessage>
			<div className='ticto-success-message'>
				<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path><path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path></svg>
				<h1>Pagamento realizado com sucesso!</h1>
				<p><Spinning /> Aguarde enquanto redirecionamos...</p>
				<ButtonClose onClick={() => setIsModalOpen(false)}>FECHAR</ButtonClose>
			</div>
		</SuccessMessage>
	)
}

export default Success;
