import { PaymentMethods } from '../contexts/OneClickBuyContext/types'

export const isCreditCard = (paymentMethod: string): boolean => {
	return paymentMethod === PaymentMethods.CARD || paymentMethod === PaymentMethods.DOUBLE_CARD
}

export const isBankSlip = (paymentMethod: string): boolean => {
	return paymentMethod === PaymentMethods.BANK_SLIP
}

export const isPix = (paymentMethod: string): boolean => {
	return paymentMethod === PaymentMethods.PIX
}
