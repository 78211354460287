import React from 'react';
import Countdown from 'react-countdown';
import { CountdownElements } from './Countdown';

const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)
const time = 1;

const MidasCountdown = () => {

	const renderer = ({ hours, minutes, seconds, completed, total }) => {
		if (completed) {
			return (
				<CountdownElements className='completed'>
					<li>
						<h1> AO FECHAR ESTA JANELA, ESTA OFERTA SERÁ PERDIDA!</h1>
					</li>
				</CountdownElements>
			)
		}
		return (
			<CountdownElements>
				<li>
					<h1>ESTA OFERTA EXPIRA EM: {String(seconds).padStart(2, '0')} SEG</h1>
				</li>
			</CountdownElements>
		)
	};
	return (
		<Countdown
			date={Date.now() + 1 * 60000}
			zeroPadTime={3}
			renderer={renderer}
		/>
	);
}
export default React.memo(MidasCountdown)
