import styled from 'styled-components'
export const ButtonReject = styled.button`
	border-radius: 08px !important;
	height: 32px !important;
	cursor: pointer !important;
	background: transparent !important;
	opacity: 0.5 !important;
	transition: all 0.2s !important;
	padding: 0 18px !important;
	border: 0 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	gap: 08px !important;
	font-weight: 500 !important;
	transition: all 0.2s !important;
	font-size: 14px !important;
	font-weight: bold !important;

	&:hover {
		transform: scale(1.05) !important;
		filter: brightness(1.1) !important;
		text-decoration: underline !important;
	}
`
