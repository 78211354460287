import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import OneClickApp from './App';
import GlobalStyle from './GlobalStyle';
import { FlowProvider } from './contexts/FlowContext';
import { OneClickProvider } from './contexts/OneClickBuyContext';

const tictoID = '84c187fd123b518f3d7f2692d7b41181a4cee31d';
const tictoElement = document.getElementById(tictoID);

if (!tictoElement) {
	const tictoRoot = document.createElement('div');
	tictoRoot.id = tictoID;
	document?.body?.appendChild(tictoRoot);
	ReactDOM.render(
		<React.StrictMode>
			<BrowserRouter>
				<FlowProvider>
					<OneClickProvider>
						<GlobalStyle />
						<OneClickApp />
					</OneClickProvider>
				</FlowProvider>
			</BrowserRouter>
		</React.StrictMode>,
		document.getElementById(tictoID)
	);
}
