import { useOneClick } from "../../../../contexts/OneClickBuyContext";
import { CardProps } from "../../../../contexts/OneClickBuyContext/types/Cards";
import { isCreditCard } from '../../../../helpers/paymentMethod';
import { CardBrand, CardFlag, CardItem, CardList, Checkbox, Container } from "./styles";

export const CardPicker: React.FC = () => {
	const { cards, setSelectedCard, selectedCard, fromPaymentMethod } = useOneClick();

	const handleSelectCard = (card: CardProps) => {
		if (selectedCard?.id === card.id) return;
		setSelectedCard(card);
	};

	if (cards.length <= 1 || !isCreditCard(fromPaymentMethod)) return null;

	return <Container>
		<p>Selecione o cartão que deseja utilizar nessa compra:</p>
		<CardList>
			{cards
				.map((card, index) => (
					<CardItem key={index} onClick={() => handleSelectCard(card)}>
						<CardFlag>
							<img src={`${process.env.REACT_APP_MIDAS_HOST}/payment_flags/${card.brand}.svg`} alt={card.brand} />
						</CardFlag>
						<CardBrand >
							<strong>**** **** **** {card.last4}</strong>
							<p>{card.brand} - {card.expiration_date}</p>
						</CardBrand>
						<Checkbox checked={selectedCard?.id === card.id} onChange={() => { }} />
					</CardItem>
				))}
		</CardList>
	</Container>

}
