const formatBRL = (number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(number);
};

const moneyMask = (value: string) => {
  if (value.length === 0) return "0.00";

  value = value.replace(".", "").replace(",", "").replace(/\D/g, "");

  const options = { minimumFractionDigits: 2 };
  let result = new Intl.NumberFormat("en-US", options).format(
    parseFloat(value) / 100
  );

  result = result.replaceAll(",", "");
  return result;
};

const convertCentsToBRL = (value: number) => {
  /*Examples:
   * 100 cents  = 1,00 BRL
   * 1000 cents = 10,00 BRL
   * 10000 cents = 100,00 BRL
   * 100000 cents = 1000,00 BRL
   */
  return formatBRL(value / 100);
};

const convertBRLToCent = (value: string) => {
  /*Examples:
		1,00 BRL = 100 cents
		10,00 BRL = 1000 cents
		100,00 BRL = 10000 cents
		1000,00 BRL = 100000 cents
	*/

  return parseFloat(value.replaceAll(/\D/g, ""));
};

const formatPriceInputDecimal = (value: string | number, decimal = 2) => {
  if (![undefined, null].includes(value)) {
    return parseFloat(String(value)).toFixed(decimal);
  } else {
    return null;
  }
};

const mapObjectAndConvertBRLToCents = (data: any) => {
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object") {
      Object.keys(data[key]).forEach((subKey) => {
        if (
          typeof data[key][subKey] === "string" &&
          data[key][subKey]?.includes("R$")
        ) {
          data[key][subKey] = convertBRLToCent(data[key][subKey]);
        }
      });
    } else {
      if (typeof data[key] === "string" && data[key].includes("R$")) {
        data[key] = convertBRLToCent(data[key]);
      }
    }
  });
};

const onlyNumbers = (value: any, returnNumber?: boolean) => {
  value = value.toString();
  const valueToReturn = value.replace(/\D/g, "");
  return returnNumber ? Number(valueToReturn) : valueToReturn;
};

export {
  formatBRL,
  onlyNumbers,
  moneyMask,
  convertCentsToBRL,
  formatPriceInputDecimal,
  convertBRLToCent,
  mapObjectAndConvertBRLToCents,
};
