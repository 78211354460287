import React from 'react'
import Countdown from 'react-countdown'
import { PixCountdownElements } from './Countdown'
import { Spinning } from '../../../Spinning'

const PixCountdown = () => {
	const renderer = ({ hours, minutes, seconds, completed, total }) => {
		return (
			<PixCountdownElements>
				<li>
					<Spinning /> Aguardando pagamento
					<span className="number">{String(minutes).padStart(2, '0')}</span>:
					<span className="number">{String(seconds).padStart(2, '0')}</span>
				</li>
			</PixCountdownElements>
		)
	}

	return <Countdown date={Date.now() + 15 * 60000} zeroPadTime={3} renderer={renderer} />
}
export default React.memo(PixCountdown)
