import { api } from '../api'

export const checkPixStatus = async (orderHash: string) => {
	return await api
		.get(`order/${orderHash}/pix`)
		.then(async (response) => {
			return response.data.status
		})
		.catch((error) => {
			console.error(error)
		})
}
