import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.8rem;
	background: var(--ticto-background);
	border-bottom: 1px solid var(--ticto-outline);
`;

export const CardList = styled.div`
	padding: 1rem;
	background: var(--ticto-shape);
	border-radius: 0.4rem;
`;

export const CardItem = styled.div`
	display: grid;
	grid-template-columns: 50px 1fr 30px;
	align-items: center;
	gap: 0.5rem;
	padding: 0 0.5rem 0;
	justify-content: space-between;
	cursor: pointer;
	height: 2.5rem;
	border-radius: 0.4rem;
	background: var(--ticto-background);
	transition: filter 0.2s;

	&:hover {
		filter: brightness(0.9);
	}

	& + div {
		margin-top: 0.4rem;
	}
`;

export const CardFlag = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	aspect-ratio: 1 / 1;
	width: 80%;
	height: 80%;
	max-width: 40px;
	border-radius: 0.5rem;
	border: 1px solid var(--ticto-outline);
	img {
		width: 100%;
		max-width: 40px;
	}
`;

export const CardBrand = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		margin: 0;
		padding: 0;
		font-size: 0.7rem;
	}
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
	appearance: none;
	width: 23px !important;
	height: 23px !important;
	border-radius: 0.2rem;
	background-color: var(--ticto-green-light);
	position: relative;

	&:checked::before {
		content: "✔";
		width: 23px;
		display: inline-block;
		font-size: 1.1rem;
		text-align: center;
		color: var(--ticto-green);
	}
`;
