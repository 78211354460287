import { AxiosError } from 'axios'
export interface ErrorMessage {
	response: {
		data: {
			message: string
		}
	}
}

const handleHTTPErrorMessage = (error: AxiosError): string => {
	let message = ''

	try {
		if (
			typeof error === undefined &&
			typeof error?.response === undefined &&
			typeof error?.response?.status === undefined
		) {
			message = (error as ErrorMessage).response.data.message ?? 'Não foi possível obter uma resposta do servidor'
		} else if (typeof error.response.data === 'string') {
			message = error.response.data;
		} else {
			if (error.response.data?.errors && error.response.data.errors[0] !== '') {
				const { errors } = error.response.data
				Object.keys(errors).map((error, index) => (message += `${errors[error]} \n\n`))
			} else {
				switch (error.response.status) {
					case 301:
						message = 'Documento movido permanentemente.'
						break
					case 302:
						message = 'Documento movido Temporariamente'
						break
					case 303:
						message = 'Os redirecionamentos não vinculam ao próprio recurso solicitado, mas a outra página'
						break
					case 304:
						message = 'Não modificado'
						break
					case 305:
						message = 'O recurso solicitado pelo cliente está disponível apenas por meio de um proxy'
						break
					case 307:
						message = 'Redirecionamento temporário'
						break
					case 308:
						message = 'Redirecionamento permanente'
						break
					case 400:
						message =
							'O servidor não pode ou não processará a solicitação devido a algo que é percebido como um erro do cliente.'
						break
					case 401:
						message = 'Não pudemos validar suas credenciais. Tente logar novamente.'
						break
					case 402:
						message = 'A solicitação não pode ser processada até que o cliente faça um pagamento'
						break
					case 403:
						message = 'Acesso Proibido'
						break
					case 404:
						message = 'Não foi possível localizar o recurso que você procura.'
						break
					case 405:
						message = 'O recurso de destino não oferece suporte ao método utilizado'
						break
					case 406:
						message =
							'O servidor não pode produzir uma resposta que corresponda à lista de valores aceitáveis ​​definidos nos cabeçalhos de negociação de conteúdo proativo da solicitação, além do mais, o servidor não está disposto a fornecer uma representação padrão.'
						break
					case 407:
						message =
							'A solicitação não foi aplicada porque não possui credenciais de autenticação válidas para um servidor proxy que está entre o app e o servidor que pode acessar o recurso solicitado.'
						break
					case 408:
						message = 'Tempo limite de espera excedido!'
						break
					case 409:
						message = 'Houve um conflito na solicitação. Tente novamente!'
						break
					case 410:
						message =
							'O acesso ao recurso de destino não está mais disponível no servidor de origem e essa condição provavelmente será permanente.'
						break
					case 411:
						message =
							'O servidor se recusou a aceitar a solicitação sem um cabeçalho Content-Length definido'
						break
					case 412:
						message = 'O acesso ao recurso de destino foi negado'
						break
					case 413:
						message = 'A requisição é maior que os limites definidos pelo servidor'
						break
					case 414:
						message = 'O URI solicitado pelo cliente é maior do que o servidor está disposto a interpretar.'
						break
					case 415:
						message =
							'O servidor se recusa a aceitar a solicitação porque o formato da carga útil está em um formato não suportado'
						break
					case 416:
						message = 'O servidor não pode atender aos intervalos solicitados'
						break
					case 417:
						message = 'A expectativa fornecida no cabeçalho Expect da solicitação não pôde ser atendida.'
						break
					case 418:
						message = 'O servidor se recusa a processar a requisição!'
						break
					case 419:
						message = 'Espaço insuficiente no recurso solicitado'
						break
					case 420:
						message = 'O método falhou. Tente novamente'
						break
					case 421:
						message = 'A solicitação foi direcionada a um servidor que não consegue produzir uma resposta'
						break
					case 422:
						message =
							'A solicitação estava bem formulada, mas não pôde ser seguida devido a erros no formulário.'
						break
					case 423:
						message = 'Recurso Bloqueado'
						break
					case 424:
						message = 'A solicitação falhou devido à falha de uma solicitação anterior!'
						break
					case 428:
						message = 'O servidor de origem exige que a solicitação seja condicional!'
						break
					case 429:
						message = 'Calma aí. Você está enviando muitas solicitações para o servidor!'
						break
					case 431:
						message =
							'O servidor não está disposto a processar a solicitação porque seus campos de cabeçalho são muito grandes'
						break
					case 451:
						message = 'O agente do usuário solicitou um recurso que não pode ser fornecido legalmente.'
						break
					case 500:
						message = 'Ocorreu um erro desconhecido ao processar a solicitação'
						break
					case 501:
						message = 'O método de solicitação não é suportado pelo servidor e não pode ser tratado.'
						break
					case 502:
						message =
							'O servidor, enquanto trabalhava como gateway para obter uma resposta necessária para lidar com a solicitação, obteve uma resposta inválida.'
						break
					case 503:
						message = 'Serviço Indisponível no momento'
						break
					case 504:
						message = 'O servidor está agindo como um gateway e não pode obter uma resposta a tempo.'
						break
					case 505:
						message = 'A versão HTTP usada na solicitação não é suportada pelo servidor.'
						break
					case 507:
						message = 'Armazenamento insuficiente no servidor'
						break
					case 511:
						message = 'O cliente precisa se autenticar para obter acesso à rede.'
						break
				}
			}
		}
	} catch (e) {
		message = 'Ocorreu um erro não mapeado ao tentar processar a requisição. '
	}

	return message
}

export { handleHTTPErrorMessage }
