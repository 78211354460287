import axios from 'axios'

export function ssrAPI(ctx = undefined, outside = false) {
	let baseURL = process.env.REACT_APP_PAYMENTS_API_URL

	const api = axios.create({
		baseURL,
		headers: {
			'X-Tic-Secret': process.env.REACT_APP_TIC_SECRET,
		},
		withCredentials: true
	})

	return api
}

export const api = ssrAPI()
