import styled from 'styled-components'

export const PixCodesContainer = styled.div`
	.pixCode {
		border-radius: 0.5rem;
		border: #dce1e6 solid 1px;
		padding: 0.5rem;
		text-align: center;
		word-wrap: break-word;
		font-size: 0.7rem;
		cursor: pointer;
		margin-bottom: 0.5rem;
	}

	.pixQrCode {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		margin: 0.8rem 0;
	}

	.logo {
		position: absolute;
		z-index: 10;
		width: 3rem !important;
		background: white;
		border-radius: 50%;
		padding: 0.5rem;
		display: flex;
	}
`
