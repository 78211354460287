import { useOneClick } from '../../../contexts/OneClickBuyContext';
import { BumpOffersProps } from '../../../contexts/OneClickBuyContext/types/Bumps';
import { convertCentsToBRL } from '../../../utils/currency';
import { Spinning } from '../../Spinning';
import { OrderBump } from './Bump';

const Bumps = () => {
	const { calculateItemsAmount, items, setItems, isCalculating, bumps } = useOneClick();

	const handleAddBump = async (e) => {
		const isChecked = e.target.checked;
		const bumpOfferCode = e.target.value;
		let _items = [...items];
		if (isChecked) {
			const hasCurrentOffer = _items.includes(bumpOfferCode);
			if (!hasCurrentOffer) {
				_items.push(bumpOfferCode);
				setItems(_items)
			}
		} else {
			_items = _items.filter(item => item !== bumpOfferCode);
			setItems(_items)
		}

		await calculateItemsAmount(_items)
	}

	if (!bumps || bumps?.length === 0) return null

	return (
		<OrderBump>
			<strong>🎉 Você pode adquirir também:  </strong>
			{
				bumps.map(bump =>
					bump.offers.map((offer: BumpOffersProps, index) => (
						<label className='bump' key={offer.code}>
							<div className='details'>
								<div className='arrow'>
									<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"></path></svg>
								</div>
								<div className='check'>
									{isCalculating
										? <Spinning />
										: (
											<label htmlFor={`check_offer_${offer.code}`}>
												<input type="checkbox" checked={items.includes(offer.code)} onChange={handleAddBump} name={`offer[${index}]`} value={offer.code} id={`check_offer_${offer.code}`} />
											</label>
										)}
								</div>
								<div className='info'>
									<strong>Adicionar {offer.name}</strong>
								</div>
							</div>

							<div className='pricing'>
								<small className='fromPrice'>de:  {offer.from_price}</small>
								<b className='price'>por: {convertCentsToBRL(offer.price)}</b>
							</div>
						</label>
					))
				)
			}
		</OrderBump>
	)
}

export default Bumps;
