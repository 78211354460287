export enum TransactionStatus {
	AUTHORIZED = 'authorized',
	CHARGEBACK = 'chargeback',
	CLOSED = 'closed',
	EXPIRED = 'expired',
	PROCESSING = 'processing',
	REFUNDED = 'refunded',
	REFUSED = 'refused',
	TRIAL = 'trial',
	WAITING_PAYMENT = 'waiting_payment',
}
