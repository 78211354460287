export function getParameterByName(name: string, url = window.location.href) {
	name = name.replace(/[\[\]]/g, '\\$&')
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url)
	if (!results) return null
	if (!results[2]) return ''
	return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const convertParamsToObject = (url: string) => {
	return Object.fromEntries(new URLSearchParams(url))
}

export const getPathFromUrl = (url) => {
	return url.split(/[?#]/)[0]
}
