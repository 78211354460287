import styled from 'styled-components'
export const ModalContainer = styled.div`
	background: rgba(0, 0, 0, 0.6) !important;
	width: 100vw !important;
	min-height: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	gap: 08px !important;
	align-items: center !important;
	justify-content: center !important;
	position: fixed !important;
	margin: 0 !important;
	top: 0 !important;
	left: 0 !important;
	z-index: 9999999999999999999999999999999999999999999 !important;
	backdrop-filter: blur(3px) !important;
	font-size: 16px !important;
	color: var(--text) !important;
	--webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	font-family: 'Inter', Arial, Helvetica, sans-serif !important;
	overflow-y: scroll;

	@media (max-width: 576px) {
		padding: 18px 0 !important;
	}

	* {
		font-family: 'Inter', Arial, Helvetica, sans-serif !important;
		box-sizing: border-box !important;
	}
`
