import React from 'react';
import Accept from './Accept';
import { ButtonsContainer } from './Container';
import Reject from './Reject';

const Buttons: React.FC = () => {
	return (
		<ButtonsContainer>
			<Accept />
			<Reject />
		</ButtonsContainer>
	)
}

export default Buttons;
