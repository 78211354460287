import React from 'react';
import { useFlow } from '../../../../contexts/FlowContext';
import { useOneClick } from '../../../../contexts/OneClickBuyContext';
import { ButtonReject } from './ButtonReject';
const Reject: React.FC = () => {
	const { isSending, isCalculating, decline } = useOneClick();
	const { flow, decline: declineWithFlow } = useFlow();
	return (
		<ButtonReject disabled={isSending || isCalculating} onClick={() => {
			flow ? declineWithFlow() : decline()
		}}>
			Não quero, obrigado.
		</ButtonReject>
	)
}

export default Reject;
