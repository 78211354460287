import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BsCheck2Circle } from 'react-icons/bs'
import PixCountdown from '../Countdown/Pix'
import { useOneClick } from '../../../contexts/OneClickBuyContext'
import { checkPixStatus } from '../../../services/PixService'
import { TransactionStatus } from '../../../contexts/OneClickBuyContext/types/Transaction'
import PixCodes from '../PixPayment/PixCodes'
import TransactionDetails from '../TransactionDetails'
import { PixPaymentContainer } from '../PixPayment/PixPaymentContainer'

const PixPayment: React.FC = () => {
	const [pixStatus, setPixStatus] = useState<TransactionStatus>(TransactionStatus.WAITING_PAYMENT)
	const { paymentResponse, redirect } = useOneClick()

	useEffect(() => {
		if (pixStatus === TransactionStatus.AUTHORIZED) {
			redirect(paymentResponse)
			return
		}

		let timer = setInterval(() => {
			checkPixStatus(paymentResponse.hash).then((response: TransactionStatus) => {
				setPixStatus(response)
			})
		}, 10000)

		return () => clearInterval(timer)
	}, [pixStatus])

	useLayoutEffect(() => {
		checkPixStatus(paymentResponse.hash)
	}, [])

	return (
		<PixPaymentContainer>
			<div className="header">
				<div className="title">
					<BsCheck2Circle />
					<h3>Pix gerado com sucesso</h3>
				</div>
				<PixCountdown />
			</div>
			<div className="content">
				<PixCodes />
				<TransactionDetails />
			</div>
		</PixPaymentContainer>
	)
}

export default PixPayment
