import styled from 'styled-components'
export const SuccessMessage = styled.div`
	padding: 16px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;

	.ticto-success-message {
		display: flex !important;
		flex-direction: column !important;
		gap: 16px !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
		margin: 0 auto !important;
		border-radius: 08px !important;
		padding: 32px !important;
		background: var(--ticto-green) !important;

		* {
			color: white;
		}

		h1 {
			text-align: center !important;
			color: var(--ticto-white) !important;
			font-size: 24px !important;
			white-space: pre-line !important;
			margin: 0 !important;
		}

		p {
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
			gap: 16px !important;
			margin: 0 !important;

			* {
				max-width: fit-content;
			}

			> svg {
				max-width: fit-content;
				height: fit-content;
				margin: 0 !important;
			}
		}

		> svg {
			width: 100px !important;
			height: 100px !important;
			color: var(--ticto-white) !important;
		}
	}
`
