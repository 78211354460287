import styled from 'styled-components'
export const Footer = styled.div`
	display: flex !important;
	flex-direction: column !important;
	gap: 08px !important;
	width: 100% !important;
	align-items: center !important;
	justify-content: center !important;
	color: var(--ticto-white) !important;
	opacity: 0.5 !important;
	svg {
		width: 80px !important;
		color: var(--ticto-white) !important;
		path {
			fill: var(--ticto-white) !important;
		}
	}
`
