import React from 'react';
import { Symbol } from '../../../components/Symbol';
import { useOneClick } from '../../../contexts/OneClickBuyContext';
import { isCreditCard } from '../../../helpers/paymentMethod';
import { convertCentsToBRL } from '../../../utils/currency';
import InstallmentsSelect from '../../InstallmentsSelect';
import { Image, Info, ProductContainer } from './Product';


const Price: React.FC = () => {
	const { offer, itemsAmount, installments, fromPaymentMethod } = useOneClick();

	return (
		<>
			<strong>{(isCreditCard(fromPaymentMethod) && installments?.labels?.length > 1) && <small>ou </small>} {convertCentsToBRL(itemsAmount)} <small>à vista</small></strong>
			<>
				{
					offer?.subscription?.first_charge_price &&
					<small className='secondPrice'>A partir da segunda cobrança: <b>{convertCentsToBRL(offer.original_price)}</b></small>
				}
			</>
		</>
	)
}

const Product: React.FC = () => {
	const { product, offer, fromPaymentMethod } = useOneClick();

	return (
		<ProductContainer>
			<Image>
				{product?.cover_picture ? <img src={product.cover_picture} alt={product.name} /> : <Symbol />}
			</Image>
			<Info>
				<h1>{product.name}</h1>
				{offer?.product_checkout_description ? (
					<small className="offer">{offer?.product_checkout_description}</small>
				) : null}
				{isCreditCard(fromPaymentMethod) && <InstallmentsSelect />}
				<Price />
			</Info>
		</ProductContainer>
	)
}

export default Product;
