import styled from 'styled-components'

export const ProductContainer = styled.div`
	display: flex !important;
	background: var(--ticto-background) !important;
	border-bottom: 1px solid var(--ticto-outline) !important;
	align-items: center !important;
	gap: 16px !important;
	padding: 16px !important;

	fieldset {
		margin: 0;
	}
`

export const Image = styled.div`
	background: var(--ticto-shape) !important;
	max-width: 100px !important;
	aspect-ratio: 1 / 1 !important;
	border: 1px solid var(--ticto-outline) !important;
	border-radius: 08px !important;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin: 16px !important;
		color: var(--ticto-primary) !important;
		max-width: 80px;
		path {
			fill: var(--ticto-primary) !important;
		}
	}
`

export const Info = styled.div`
	width: 100% !important;
	height: 100% !important;
	display: flex;
	flex-direction: column;
	gap: 8px;
	h1 {
		color: var(--ticto-primary) !important;
		font-size: 20px !important;
		font-weight: bold !important;
		line-height: 30px !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	p {
		font-size: 13px !important;
		margin: 0 !important;
		opacity: 0.5 !important;
	}

	small.offer {
		display: block !important;
		color: var(--ticto-text);
	}

	small.secondPrice {
		display: block !important;
		color: var(--ticto-text);
		opacity: 0.5 !important;
	}

	span {
		font-size: 13px !important;
		text-decoration: line-through !important;
		color: var(--ticto-red) !important;
		margin-right: 16px !important;
	}

	strong {
		color: var(--ticto-green) !important;
		font-size: 20px !important;
	}

	small {
		font-weight: normal !important;
		font-size: 14px !important;
	}
`
