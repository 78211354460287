import React from 'react';
import { useOneClick } from '../../../../contexts/OneClickBuyContext';
import { Spinning } from '../../../Spinning';
import { ButtonAccept } from './ButtonAccept';
const Accept: React.FC = () => {
	const { isSending, isCalculating, pay } = useOneClick();
	return (
		<ButtonAccept disabled={isSending || isCalculating} onClick={() => pay()}>
			{isSending ? <Spinning /> : 'COMPRAR COM UM CLIQUE'}
		</ButtonAccept>
	)
}

export default Accept;
