import Clipboard from '../../../Clipboard'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ButtonSuccess } from '../../styles/ButtonSuccess'
import QRCode from 'react-qr-code'
import { Symbol } from '../../../Symbol'
import { useOneClick } from '../../../../contexts/OneClickBuyContext'
import { useState } from 'react'
import { BsCheck2Circle } from 'react-icons/bs'
import { MdOutlineContentCopy } from 'react-icons/md'
import { PixCodesContainer } from './PixCodesContainer'

const PixCodes: React.FC = () => {
	const { actualTransaction } = useOneClick()
	const [clipboard, setClipboard] = useState({
		value: '',
		copied: false,
	})
    
	return (
		<PixCodesContainer>
			<div className="pixCode">
				<Clipboard text={actualTransaction?.pix_qr_code} clipboard={clipboard} setClipboard={setClipboard} />
			</div>
			<CopyToClipboard text={actualTransaction?.pix_qr_code}>
				<ButtonSuccess onClick={null}>
					{clipboard.copied ? (
						<>
							Copiado <BsCheck2Circle />
						</>
					) : (
						<>
							Copiar a chave "copia e cola" <MdOutlineContentCopy />
						</>
					)}
				</ButtonSuccess>
			</CopyToClipboard>
			<div className="pixQrCode">
				<QRCode value={actualTransaction?.pix_qr_code} />
				<div className="logo">
					<Symbol />
				</div>
			</div>
		</PixCodesContainer>
	)
}

export default PixCodes
