
function Logotype() {
	return (

		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			enableBackground="new 0 0 254 87"
			version="1.1"
			viewBox="0 0 254 87"
			xmlSpace="preserve"
		>
			<path d="M124.9 63.3c-.6 3.6-2.2 6.6-4.5 8.9-3.1 3.1-7.3 4.6-12.5 4.6-5.6 0-10.1-2.2-13.5-6.5-3.4-4.3-5.1-10-5.1-17.2 0-6.8 1.7-12.2 5.1-16.2s7.9-6 13.5-6c5.2 0 9.4 1.5 12.6 4.5 2.5 2.4 4 5.4 4.6 9.1h12.3c-.6-4.1-1.9-7.7-3.9-11-2.5-4.1-6-7.2-10.4-9.4s-9.5-3.3-15.1-3.3c-6.1 0-11.5 1.3-16.2 4-4.7 2.7-8.3 6.5-10.9 11.4-2.6 5-3.9 10.7-3.9 17.2 0 6.9 1.3 12.9 4 17.9 2.7 5 6.3 8.9 11 11.6 4.7 2.7 10 4 16.1 4 5.6 0 10.7-1.1 15.1-3.3 4.4-2.2 7.9-5.3 10.5-9.4 2-3.2 3.3-6.9 3.8-11.1h-12.6zM41.9 31.1v-8.3H24.6V9.5h-4.3l-6 10c-1.2 2.1-3.2 3.7-5.5 4.5l-7 2.4v4.7h10.6V69c0 4.6.7 8.1 2.1 10.5 1.4 2.4 3.5 4 6.5 4.9 2.9.8 7.1 1.3 12.4 1.3 2.6 0 5.4-.1 8.6-.4V75.2c-3.1.2-5.8.4-8.1.4-3.5 0-5.9-.5-7.2-1.6-1.3-1-2-3.2-2-6.4V31.1h17.2zM190.9 71.2c-3-5.1-4.5-10.9-4.5-17.5 0-6.5 1.5-12.3 4.5-17.2 3-5 7-8.8 12.1-11.5 5.1-2.7 10.5-4.1 16.4-4.1 5.9 0 11.3 1.4 16.4 4.1 5.1 2.7 9.1 6.6 12.1 11.5 3 5 4.5 10.7 4.5 17.2 0 6.6-1.5 12.4-4.5 17.5s-7 9-12 11.7c-5 2.7-10.5 4.1-16.5 4.1s-11.5-1.4-16.5-4.1c-5-2.7-9-6.6-12-11.7zm49.2-17.5c0-7.1-1.9-12.6-5.6-16.7-3.7-4-8.8-6-15-6-6.2 0-11.2 2-14.9 6-3.7 4-5.6 9.6-5.6 16.7 0 7.2 1.9 12.9 5.6 17 3.7 4.1 8.7 6.2 14.9 6.2 6.3 0 11.3-2.1 15-6.2 3.7-4.1 5.6-9.7 5.6-17zM54 22.8h12.2v62.3H54V22.8z"></path>
			<circle cx="60.1" cy="9.5" r="7.1"></circle>
			<path d="M181.2 31.1v-8.3h-17.3V9.5h-4.3l-6 10c-1.2 2.1-3.2 3.7-5.5 4.5l-7 2.4v4.7h10.6V69c0 4.6.7 8.1 2.1 10.5 1.4 2.4 3.5 4 6.5 4.9 2.9.8 7.1 1.3 12.4 1.3 2.6 0 5.4-.1 8.6-.4V75.2c-3.1.2-5.8.4-8.1.4-3.5 0-5.9-.5-7.2-1.6-1.3-1-2-3.2-2-6.4V31.1h17.2z"></path>
		</svg>
	);
}

export default Logotype;
