import { TransactionDetailsContainer } from './TransactionDetailsContainer'
import { useOneClick } from '../../../contexts/OneClickBuyContext'

const TransactionDetails: React.FC = () => {
	const { paymentResponse, actualTransaction } = useOneClick()

	return (
		<TransactionDetailsContainer>
			<p>
				<b>Produto Adquirido: </b> {paymentResponse.items[0].product.name}
			</p>
			<p>
				<b>Número do Pedido: </b> {String(paymentResponse.id).padStart(8, '0')}
			</p>
			<p>
				<b>Código do Pedido: </b>
				<span>{paymentResponse.hash}</span>
			</p>
			<p>
				<b>Código da Transação:</b>
				<span>{actualTransaction.hash}</span>
			</p>
		</TransactionDetailsContainer>
	)
}

export default TransactionDetails
